module.exports = {
	'selectLanguage': 'ru',
	'up': 'Вверх',
	'toSimulatorButton': 'Начать практику',
	'englishBlockContent': {
		'title': 'Английский, Английский и еще раз Английский!',
		'text1': 'Если ты хочешь работать над крутыми проектами и хорошо зарабатывать, думаю, не будет преувеличением сказать, что английский так же важен как и хард скилы.',
		'text2': 'В начале моей карьеры у меня были проблемы с английским. Во первых, был страх говорить с носителями языка, а иногда я просто заваливал собеседование когда доходило до инглиша. Я и сейчас продолжаю заниматься с носителем языка, так как это самый эффективный способ изучения. Я пользуюсь сервисом PrePly, там много нейтив преподавателей, с которыми можно заниматься в удобное время.',
		'text3': 'Используя мою ссылку ты получишь скидку 70% на первый урок.',
		'button': 'Перейти',
	},
	'author': 'Автор',
	'anotherQuestions': 'Другие вопросы',
	'questionsNote': 'Если у тебя есть вопросы или предложения, напиши мне:',
};