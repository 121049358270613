import React from 'react';
import Layout from './index';
import messages from '../../constants/messages/ru';

const LayoutRu = (props) => {
	return(
		<Layout
			{...props}
			i18nMessages={messages}
		/>
	)
}

export default LayoutRu;